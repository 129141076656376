import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { BodyCopy } from "acca-design-system";

const OxfordBrookesDisplay = ({ content, status }) => {
  const { quote, options, restore, label } = content;
  const option = options.find(c => c.value === (status ? status.toString() : null));

  return status === false ? (
    <BodyCopy>{Parser(restore)}</BodyCopy>
  ) : (
    <Fragment>
      <BodyCopy className="u-margin-bottom-2">{Parser(quote)}</BodyCopy>
      <dl>
        <dt>{label}</dt>
        <dd>{option ? Parser(`${option.label} - ${option.text}`) : "Not yet selected"}</dd>
      </dl>
    </Fragment>
  );
};

OxfordBrookesDisplay.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    quote: PropTypes.string,
    warning: PropTypes.string,
    restore: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  }).isRequired,
  status: PropTypes.string,
};

export default OxfordBrookesDisplay;
