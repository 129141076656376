import sha256 from "crypto-js/sha256";

export const initUserData = accaId => {
  const saltKey = process.env.AUTH_CLIENT_SALT;

  window.digitalData = window.digitalData || {};
  window.digitalData.user = window.digitalData.user || [];
  window.digitalData.user[0] = {
    isLoggedIn: true,
    userID: sha256(accaId + saltKey).toString(),
  };
};

export const trackPageView = (pageName, userType, customPath) => {
  window.digitalData = window.digitalData || {};
  window.digitalData.page = window.digitalData.page || {};
  const pathFragment = customPath || window.location.hash.substr(1);

  const path = userType ? `/${userType + pathFragment}` : pathFragment;
  const url = `${window.location.href.split("#")[0]}#${pathFragment}`;
  const prevPageInfo = window.digitalData.page.pageInfo || {};

  window.digitalData.page.pageInfo = {
    ...prevPageInfo,
    url,
    pageName,
    path,
  };
  const customEvent = document.createEvent("CustomEvent");
  customEvent.initCustomEvent("page-view", false, false, {
    url,
    pageName,
    path,
  });
  document.body.dispatchEvent(customEvent);
  return window.digitalData;
};

export const trackEvent = ({ label, ...detail }) => {
  window.digitalData = window.digitalData || {};
  window.digitalData.events = window.digitalData.events || [];
  window.digitalData.events.push({ label, detail });

  const customEvent = document.createEvent("CustomEvent");
  customEvent.initCustomEvent("custom-event", false, false, {
    label,
    detail,
  });

  document.body.dispatchEvent(customEvent);

  return window.digitalData;
};
