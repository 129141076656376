import { ACTION_TYPES } from "./actions";

export const initialState = {
  loaded: false,
  hasError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_QUALIFICATIONS:
      return initialState;
    case ACTION_TYPES.FETCH_QUALIFICATIONS_SUCCESS:
      return {
        qualificationProgress: action.payload.qualificationProgress,
        loaded: true,
        hasError: false,
      };
    case ACTION_TYPES.FETCH_QUALIFICATIONS_ERROR:
      return {
        ...state,
        hasError: true,
      };
    default:
      return state;
  }
};

export default reducer;
