import React from "react";
import PropTypes from "prop-types";
import { Button, IconOpenInANewWindow16, TileGrid, Tile, VideoTile } from "acca-design-system";

import Container from "components/Container/Container";
import "./tile-grid.scss";
import { isExternaLink } from "utilities/Helpers";

const TileGridSection = ({ content }) => {
  const renderTile = ({ title, video, link, image }, idx) => {
    if (video) {
      return (
        <VideoTile
          key={idx}
          label={title}
          heading={link.text}
          backgroundImages={image ? [{ src: image }] : null}
          url={video}
        />
      );
    }
    return (
      <Tile
        key={idx}
        label={title}
        heading={link.text}
        backgroundImages={image ? [{ src: image }] : null}
        onClick={() =>
          link && link.href
            ? window.open(link.href, isExternaLink(link.href) ? "_blank" : "_self")
            : null
        }
      />
    );
  };

  const { heading, tiles, button } = content;
  return (
    <Container className="section tile-grid-section">
      <div className="cell screen768-18">
        <h3 className="h600 u-font-bold">{heading}</h3>
      </div>
      <div className="cell screen768-order-2">
        <div>
          <TileGrid>{tiles.map((tile, idx) => renderTile(tile, idx))}</TileGrid>
        </div>
      </div>
      {button && (
        <div className="tile-grid__link cell screen768-6 screen768-order-1 align-self-bottom">
          <Button
            text={button.text}
            variant="link"
            to={button.href}
            iconPosition="right"
            icon={IconOpenInANewWindow16}
          />
        </div>
      )}
    </Container>
  );
};

TileGridSection.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    tiles: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        video: PropTypes.string,
        image: PropTypes.string,
        link: PropTypes.shape({
          text: PropTypes.string,
          href: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  }),
};

export default TileGridSection;
