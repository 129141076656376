import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { BodyCopy, FormField } from "acca-design-system";
import RadioButtonGroup from "components/Form/RadioButtonGroup/RadioButtonGroup";

const OxfordBrookesInput = ({ content, status, onRadioButtonChange }) => {
  const { quote, warning, options } = content;

  const newOptions = options.map(option => {
    if (option.value === "false") {
      return { ...option, warn: true };
    }

    return option;
  });

  return (
    <Fragment>
      <BodyCopy className="u-margin-bottom-2">{Parser(quote)}</BodyCopy>
      <FormField>
        <RadioButtonGroup
          id="oxford"
          name="oxford"
          options={newOptions}
          value={status ? status.toString() : null}
          boxed
          warning={warning}
          handleChange={onRadioButtonChange}
        />
      </FormField>
    </Fragment>
  );
};

OxfordBrookesInput.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    quote: PropTypes.string,
    warning: PropTypes.string,
    restore: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  }).isRequired,
  status: PropTypes.bool,
  onRadioButtonChange: PropTypes.func,
};

export default OxfordBrookesInput;
