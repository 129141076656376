import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setAccaId, fetchUserProfile } from "state/profile/actions";
import RootPage from "pages/RootPage/RootPage";
import AuthService from "services/AuthService";
import {
  getAccaId,
  getIsPortalExperienceSupported,
  getPortalExperience,
} from "state/profile/selectors";
import UnsupportedPage from "pages/UnsupportedPage/UnsupportedPage";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import rollbar from "error-logger";
import PageContentLoader from "../PageContentLoader/PageContentLoader";
import "styles/app.scss";
import { initUserData } from "../../analytics/trackEvents";

export const getAuthGroups = user => {
  if (!user?.profile?.groups) {
    return [];
  }

  const groupsConfig = Array.isArray(user.profile.groups)
    ? user.profile.groups
    : [user.profile.groups];

  const groups = groupsConfig.map(group => {
    const cn = group
      .split(",")
      .map(pair => pair.split("="))
      .find(pair => pair[0] === "cn");

    return cn?.length === 2 ? cn[1] : null;
  });

  return groups.filter(x => !!x);
};

const mapStateToProps = state => ({
  accaId: getAccaId(state),
  isExperienceSupported: getIsPortalExperienceSupported(state),
  portalExperience: getPortalExperience(state),
});

const mapDispatchToProps = dispatch => ({
  setUsersAccaId: accaId => dispatch(setAccaId(accaId)),
  getUserProfile: accaId => dispatch(fetchUserProfile(accaId)),
});

class AppInitialiser extends Component {
  constructor(props) {
    super(props);
    this.state = { authError: false };
  }

  componentDidMount() {
    const { getUser, userManager, login } = AuthService;
    getUser()
      .then(user => {
        if (!user || user.expired) {
          window.sessionStorage.setItem("returnUrl", window.location.hash);
          login().catch(error => {
            this.setState({ authError: true });
            rollbar.error("Login error", error);
          });
        } else {
          const { setUsersAccaId, getUserProfile, invalidAuthGroupRedirect } = this.props;
          const authGroups = getAuthGroups(user);
          if (authGroups?.length > 0) {
            const redirectOnInvalidAuthGroup = invalidAuthGroupRedirect(authGroups);
            if (redirectOnInvalidAuthGroup) {
              window.location.href = redirectOnInvalidAuthGroup;
            }
          }
          setUsersAccaId(user.profile.sub);
          getUserProfile(user.profile.sub);
          initUserData(user.profile.sub);
        }
      })
      .catch(ex => {
        throw ex;
      });

    userManager.events.addSilentRenewError(() => {
      AuthService.userManager.clearStaleState().then(() => {
        AuthService.userManager.removeUser();
        window.location.replace("/logged-out.html");
      });
    });

    userManager.events.addUserSignedOut(() => {
      userManager.clearStaleState().then(() => {
        userManager.removeUser();
        window.location.replace("/logged-out.html");
      });
    });
  }

  componentWillUnmount() {
    AuthService.userManager.events.removeUserSignedOut();
    AuthService.userManager.events.removeSilentRenewError();
  }

  render() {
    const { authError } = this.state;
    if (authError) {
      return <ErrorPage isTakeOver />;
    }

    const { accaId, isExperienceSupported, portalExperience } = this.props;
    const isPageReady = accaId && isExperienceSupported;
    if (!isExperienceSupported) {
      if (portalExperience === "UNSUPPORTED") {
        return <UnsupportedPage />;
      }
      window.location = process.env.UNSUPPORTED_CUSTOMER_REDIRECT;
    }
    return isPageReady ? <RootPage /> : <PageContentLoader />;
  }
}
AppInitialiser.propTypes = {
  getUserProfile: PropTypes.func.isRequired,
  setUsersAccaId: PropTypes.func.isRequired,
  isExperienceSupported: PropTypes.bool.isRequired,
  portalExperience: PropTypes.string,
  accaId: PropTypes.string,
  invalidAuthGroupRedirect: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppInitialiser));
